import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory } from "react-router-dom";

import CentralizedModal from "components/centralizedModal";
import { useLanguageSettings } from "hooks/language-settings";

import { ReactComponent as RightArrow } from "../../assets/icons/arrow-right.svg";
import { ReactComponent as Close } from "../../assets/icons/close-black.svg";
import { ReactComponent as English } from "../../assets/icons/en-us-white.svg";
import { ReactComponent as GrayEnglish } from "../../assets/icons/en-us.svg";
import { ReactComponent as Spanish } from "../../assets/icons/es-es-white.svg";
import { ReactComponent as GraySpanish } from "../../assets/icons/es-es.svg";
import { ReactComponent as Portuguese } from "../../assets/icons/pt-br-white.svg";
import { ReactComponent as GrayPortuguese } from "../../assets/icons/pt-br.svg";
import { ModalContent } from "./modal-content";

import "./styles.css";

const LinkWhatsapp = process.env.REACT_APP_WHATSAPP;
const isWhitelabel = Number(process.env.REACT_APP_IS_WHITELABEL);

const { location } = window;
const event_id = new URLSearchParams(location.search).get("event_id");

export const commonSharedPaths = [
  "/eventos",
  "/establishments",
  "/organizacoes",
  "/faqs",
  "/personalizations",
  "/event-categories",
  "/ticket-categories",
  "/PdvList",
  "/gestao-termo-de-uso",
  "/auditoria/emails",
  "/auditoria/antiFraud",
  "/auditoria/SalesTickets",
  "/auditoria/sales-details",
  "/products",
  "/products-categories",
  "/equipments/my-equipments",
  "/equipments/equipment-list",
  "/equipments/equipment-types",
  "/equipments/equipment-control",
  "/equipments/inventory",
  "/organizacao",
  "/user-data",
  "/dashboard",
  "/generalReport",
  "/internal-reports",
  "/financeiro-dashboard",
  "/financeiro-planos",
  "/financeiro-transferencias",
  "/users",
  "/mapas",
  "/mapas/novo",
  /\/mapas\/.*/,
  "/registerTerms",
  "/registerPDV",
  "/preferences",
];

export const ticketsSharedPaths = [
  "/tickets/dashboard",
  "/tickets/ingressos/evento",
  "/tickets/courtesies",
  "/tickets/ingressos/grupos/evento",
  "/tickets/ingressos/codigos/evento",
  "/tickets/ingressos/comissarios/evento",
  "/tickets/ingressos/SortingOfSectors/evento",
  "/tickets/ingressos/localizadores/evento",
  "/tickets/vendas",
  "/tickets/comissarios/vendas",
  "/tickets/event-sales-date/vendas",
  "/tickets/PDV/vendas",
  "/tickets/participantes/evento",
  "/tickets/checkin",
  "/tickets/new-event-edit/evento",
  "/tickets/new-event-edit/marketing/evento",
  "/tickets/new-event-edit/collaborators/evento",
  "/tickets/new-event-edit/salesConfig/evento",
  "/tickets/financeiro-transferencias",
  "/tickets/financeiro-bordero",
  "/tickets/seatsmapslist",
  "/tickets/marketing/audienceAndVisits",
  "/tickets/integrations",
  "/tickets/nominal-transfers",
];

export const barSharedPaths = [
  `/register-establishment/${event_id}`,
  "/register-establishment",
  "/bar/dashboard",
  "/bar/establishments-sold",
  "/bar/event-commands",
  "/bar/menu-event-new",
  "/bar/event-report",
  "/bar/filterCaixa",
  "/bar/config",
];

export const sidebarData = [
  {
    label: "back",
    icon: "/icons/back.svg",
    matchPath: [...ticketsSharedPaths, ...barSharedPaths],
    redirectPath: "/eventos",
    permittedRoles: ["USER", "GOD"],
  },
  {
    label: "reports",
    icon: "/icons/reports.svg",
    matchPath: commonSharedPaths,
    subItems: [
      { label: "dashboard", redirectPath: "/dashboard" },
      { label: "generalSales", redirectPath: "/generalReport" },
      { label: "internalReports", redirectPath: "/internal-reports" },
    ],
    permittedRoles: ["GOD"],
  },
  {
    label: "events",
    icon: "/icons/events.svg",
    matchPath: commonSharedPaths,
    redirectPath: "/eventos",
    permittedRoles: ["USER", "GOD"],
  },
  {
    label: "establishments",
    icon: "/icons/establishments.svg",
    matchPath: commonSharedPaths,
    redirectPath: "/establishments",
    permittedRoles: ["GOD"],
  },
  {
    label: "financial",
    icon: "/icons/financial.svg",
    matchPath: commonSharedPaths,
    subItems: [
      { label: "overview", redirectPath: "/financeiro-dashboard" },
      { label: "paymentPlans", redirectPath: "/financeiro-planos" },
      { label: "transfers", redirectPath: "/financeiro-transferencias" },
    ],
    permittedRoles: ["GOD"],
  },
  {
    label: "organizations",
    icon: "/icons/organization.svg",
    matchPath: commonSharedPaths,
    redirectPath: "/organizacoes",
    permittedRoles: ["USER", "GOD"],
  },
  {
    label: "user",
    icon: "/icons/users.svg",
    matchPath: commonSharedPaths,
    redirectPath: "/users",
    permittedRoles: ["GOD"],
  },
  {
    label: "equipments",
    icon: "/icons/equipments.svg",
    matchPath: commonSharedPaths,
    subItems: [
      {
        label: "list",
        redirectPath: "/equipments/equipment-list",
        permittedRoles: ["GOD"],
      },
      {
        label: "category",
        redirectPath: "/equipments/equipment-types",
        permittedRoles: ["GOD"],
      },
      {
        label: "control",
        redirectPath: "/equipments/equipment-control",
        permittedRoles: ["GOD"],
      },
      {
        label: "inventory",
        redirectPath: "/equipments/inventory",
        permittedRoles: ["GOD"],
      },
      {
        label: "myEquipment",
        redirectPath: "/equipments/my-equipments",
        permittedRoles: ["GOD"],
      },
    ],
    permittedRoles: ["GOD"],
  },
  {
    label: "products",
    icon: "/icons/products.svg",
    matchPath: commonSharedPaths,
    subItems: [
      { label: "list", redirectPath: "/products" },
      { label: "category", redirectPath: "/products-categories" },
    ],
    permittedRoles: ["GOD"],
  },
  {
    label: "audit",
    icon: "/icons/audit.svg",
    matchPath: commonSharedPaths,
    subItems: [
      { label: "mails", redirectPath: "/auditoria/emails" },
      { label: "audit", redirectPath: "/auditoria/SalesTickets" },
      ...(!isWhitelabel
        ? [{ label: "auditSales", redirectPath: "/auditoria/sales-details" }]
        : []),
    ],
    permittedRoles: ["GOD"],
  },
  {
    label: "dashboard",
    icon: "/icons/dashboard.svg",
    matchPath: ticketsSharedPaths,
    redirectPath: `/tickets/dashboard?event_id=:id`,
    permittedRoles: ["USER", "GOD"],
  },
  {
    label: "tickets",
    icon: "/icons/tickets.svg",
    matchPath: ticketsSharedPaths,
    subItems: [
      {
        label: "ticketSettings",
        redirectPath: `/tickets/ingressos/evento?event_id=:id`,
      },
      {
        label: "sortSectors",
        redirectPath: `/tickets/ingressos/SortingOfSectors/evento?event_id=:id`,
      },
      {
        label: "passports",
        redirectPath: `/tickets/ingressos/grupos/evento?event_id=:id`,
      },
      {
        label: "promoCodes",
        redirectPath: `/tickets/ingressos/codigos/evento?event_id=:id`,
      },
      {
        label: "promoters",
        redirectPath: `/tickets/ingressos/comissarios/evento?event_id=:id`,
      },
      {
        label: "coutesies",
        redirectPath: `/tickets/courtesies?event_id=:id`,
      },
    ],
    permittedRoles: ["USER", "GOD"],
  },
  {
    label: "sales",
    icon: "/icons/transactions.svg",
    matchPath: ticketsSharedPaths,
    subItems: [
      {
        label: "detailedExtract",
        redirectPath: `/tickets/vendas?event_id=:id`,
      },
      {
        label: "participants",
        redirectPath: `/tickets/participantes/evento?event_id=:id`,
      },
      {
        label: "transferedTickets",
        redirectPath: `/tickets/nominal-transfers?event_id=:id`,
      },
    ],
    permittedRoles: ["USER", "GOD"],
  },
  {
    label: "reports",
    icon: "/icons/reports.svg",
    matchPath: ticketsSharedPaths,
    subItems: [
      {
        label: "dailySales",
        redirectPath: `/tickets/event-sales-date/vendas?event_id=:id`,
      },
      {
        label: "promoterSales",
        redirectPath: `/tickets/comissarios/vendas?event_id=:id`,
      },
      {
        label: "posSales",
        redirectPath: `/tickets/PDV/vendas?event_id=:id`,
      },
    ],
    permittedRoles: ["USER", "GOD"],
  },

  {
    label: "marketing",
    icon: "/icons/megaphone-2.svg",
    matchPath: ticketsSharedPaths,
    subItems: [
      {
        label: "visitsAudience",
        redirectPath: `/tickets/marketing/audienceAndVisits?event_id=:id`,
      },
    ],
    permittedRoles: ["USER", "GOD"],
  },
  {
    label: "accessAndValidation",
    icon: "/icons/checkin.svg",
    matchPath: ticketsSharedPaths,
    subItems: [
      {
        label: "accessReports",
        redirectPath: `/tickets/checkin?event_id=:id`,
      },
      {
        label: "locators",
        redirectPath: `/tickets/ingressos/localizadores/evento?event_id=:id`,
      },
    ],
    permittedRoles: ["USER", "GOD"],
  },
  {
    label: "financial",
    icon: "/icons/financial.svg",
    matchPath: ticketsSharedPaths,
    subItems: [
      {
        label: "transfers",
        redirectPath: `/tickets/financeiro-transferencias?event_id=:id`,
      },
      {
        label: "financialStatement",
        redirectPath: "/tickets/financeiro-bordero?event_id=:id",
      },
    ],
    permittedRoles: ["USER", "GOD"],
  },
  {
    label: "settings",
    icon: "/icons/config.svg",
    matchPath: ticketsSharedPaths,
    subItems: [
      {
        label: "eventInformations",
        redirectPath: "/tickets/new-event-edit/evento?event_id=:id",
      },
      {
        label: "colaborators",
        redirectPath:
          "/tickets/new-event-edit/collaborators/evento?event_id=:id&position=4",
      },
      {
        label: "salesSettings",
        redirectPath:
          "/tickets/new-event-edit/salesConfig/evento?event_id=:id&position=3",
      },
      {
        label: "integrations",
        redirectPath: "/tickets/integrations?event_id=:id",
      },
    ],
    permittedRoles: ["USER", "GOD"],
  },
  {
    label: "settings",
    icon: "/icons/config.svg",
    matchPath: commonSharedPaths,
    subItems: [
      { label: "personalizations", redirectPath: "/personalizations" },
      { label: "eventCategories", redirectPath: "/event-categories" },
      { label: "ticketCategories", redirectPath: "/ticket-categories" },
      { label: "posManagement", redirectPath: "/PdvList" },
      { label: "posTerms", redirectPath: "/gestao-termo-de-uso" },
      { label: "preferences", redirectPath: "/preferences" },
    ],
    permittedRoles: ["GOD"],
  },

  {
    label: "seatingMaps",
    icon: "/icons/seatingChart.svg",
    matchPath: commonSharedPaths,
    redirectPath: "/mapas",
    permittedRoles: ["GOD"],
  },
  {
    label: "seatingMaps",
    icon: "/icons/seatingChart.svg",
    matchPath: ticketsSharedPaths,
    redirectPath: "/tickets/seatsmapslist?event_id=:id",
    permittedRoles: ["USER", "GOD"],
  },
  {
    label: "faqs",
    icon: "/icons/faqs.svg",
    matchPath: commonSharedPaths,
    redirectPath: "/faqs",
    permittedRoles: ["GOD"],
  },
  {
    label: "dashboard",
    icon: "/icons/dashboard.svg",
    matchPath: barSharedPaths,
    redirectPath: `/bar/dashboard?event_id=:id`,
    permittedRoles: ["USER", "GOD"],
  },
  {
    label: "transactions",
    icon: "/icons/transactions.svg",
    matchPath: barSharedPaths,
    redirectPath: `/bar/establishments-sold?event_id=:id`,
    permittedRoles: ["USER", "GOD"],
  },
  {
    label: "checks",
    icon: "/icons/bartab.svg",
    matchPath: barSharedPaths,
    subItems: [
      { label: "Geral", redirectPath: `/bar/event-commands?event_id=:id` },
    ],
    permittedRoles: ["USER", "GOD"],
  },
  {
    label: "menu",
    icon: "/icons/menu.svg",
    matchPath: barSharedPaths,
    redirectPath: `/bar/menu-event-new?event_id=:id`,
    permittedRoles: ["USER", "GOD"],
  },
  {
    label: "reports",
    icon: "/icons/reports.svg",
    matchPath: barSharedPaths,
    subItems: [
      { label: "Geral", redirectPath: `/bar/event-report?event_id=:id` },
      {
        label: "Por caixas e terminais",
        redirectPath: `/bar/filterCaixa?event_id=:id`,
      },
    ],
    permittedRoles: ["USER", "GOD"],
  },
  {
    label: "abSettings",
    icon: "/icons/config.svg",
    matchPath: barSharedPaths,
    redirectPath: `/bar/config?event_id=:id`,
    permittedRoles: ["USER", "GOD"],
  },
  {
    label: "support",
    icon: "/icons/chat-two-bubbles-oval.svg",
    matchPath: [...ticketsSharedPaths, ...commonSharedPaths, ...barSharedPaths],
    subItems: [
      {
        externalLink: true,
        label: "Whatsapp",
        redirectPath: LinkWhatsapp,
        target: "_blank",
      },
    ],
    permittedRoles: ["USER", "GOD"],
  },
  {
    label: "terms",
    icon: "/icons/termsOfUse.svg",
    secondaryIcon: "/icons/externalLink",
    matchPath: commonSharedPaths,
    permittedRoles: ["USER", "GOD"],
  },
  {
    component: ChangeLanguageBtn,
  },
];

if (isWhitelabel) {
  const index = sidebarData.findIndex((item) => item.label === "Marketing");
  if (index !== -1) {
    sidebarData.splice(index, 1);
  }
}

function SidebarItem(props) {
  const {
    data,
    index,
    parentProps,
    onSubItemToggle,
    isExpanded,
    DropDown,
    isOpen,
    eventId,
  } = props;
  const history = useHistory();
  const isMobile = window.matchMedia("(max-width: 768px)");

  const exclusiveMobileBehavior = () => {
    if (isMobile.matches) {
      parentProps.setSidebarClassName("sidebar-toggle");
    }
  };

  const toggleExpand = (e) => {
    e.preventDefault();
    parentProps.setSidebarClassName("sidebar-call");
    document.documentElement.style.setProperty("--sidebar-width", "13.75rem");

    if (data.secondaryIcon) {
      window.open("/termos-de-uso", "_blank");
    }
    if (!data.subItems) {
      const path = verifyPath(data.redirectPath);
      history.push(path);
      exclusiveMobileBehavior();
      DropDown();
    } else {
      onSubItemToggle(index, !isExpanded);
      DropDown();
    }
  };

  const verifyPath = (path) => {
    if (path) {
      if (path.includes(":id")) {
        path = path.replace(":id", eventId);
      }
    }
    return path;
  };
  const { t } = useTranslation("sidebar");

  if (data.component) {
    const { component: Component } = data;
    return <Component />;
  }

  return (
    <li>
      <NavLink
        activeClassName="nav-active"
        to={() => verifyPath(`${data.redirectPath}`)}
        onClick={toggleExpand}
        className="item-container"
      >
        <img src={data.icon} alt={data.label} className="icons" />
        <span>{t(data.label)}</span>
        {data.subItems && (
          <img
            src="/icons/closedArrow.svg"
            alt="arrow icon"
            className={isOpen ? "arrow-icon arrow-icon-open" : "arrow-icon"}
          />
        )}
        {data.secondaryIcon && (
          <img
            src={"/icons/externalLink.svg"}
            alt="external link"
            className="external-link"
          />
        )}
      </NavLink>
      {data.subItems && (
        <div className={isOpen ? "sub-items-group" : "sub-items-group-closed"}>
          {data.subItems.map((subItem, subIndex) =>
            subItem.externalLink ? (
              <a
                key={subIndex}
                className="sub-item"
                href={subItem.redirectPath}
                target={subItem.target}
              >
                {t(subItem.label)}
              </a>
            ) : (
              <NavLink
                exact
                key={subIndex}
                className="sub-item "
                target={subItem.target || ""}
                activeClassName="nav-active"
                to={() => verifyPath(`${subItem.redirectPath}`)}
                onClick={exclusiveMobileBehavior}
              >
                {t(subItem.label)}
              </NavLink>
            )
          )}
        </div>
      )}
    </li>
  );
}

const localeOptions = [
  {
    language: "pt-BR",
    label: "portuguese",
    icon: GrayPortuguese,
  },
  {
    language: "es-ES",
    label: "spanish",
    icon: GraySpanish,
  },
  {
    language: "en-US",
    label: "english",
    icon: GrayEnglish,
  },
];

function ChangeLanguageBtn() {
  const { t, i18n } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { setLanguage } = useLanguageSettings();

  const handleChangeLang = (lang) => {
    setLanguage(lang);
    setIsModalOpen(false);
  };

  const Icon = useCallback(() => {
    switch (i18n.language) {
      case "es-ES":
        return <Spanish />;
      case "pt-BR":
        return <Portuguese />;
      default:
        return <English />;
    }
  }, [i18n.language]);
  return (
    <>
      <div
        className="item-container change-language"
        onClick={() => setIsModalOpen(true)}
      >
        <div className="icons">
          <Icon />
        </div>
        <span>{t("sidebar:changeLanguage")}</span>
      </div>
      <CentralizedModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
      >
        <ModalContent>
          <div className="head">
            <h1>{t("sidebar:changeSystemLanguage")}</h1>
            <Close onClick={() => setIsModalOpen(false)} cursor={"pointer"} />
          </div>
          <div className="options">
            {localeOptions.map((lang, index) => {
              const { icon: Icon, label, language } = lang;
              return (
                <div
                  key={index}
                  className="option"
                  onClick={() => handleChangeLang(language)}
                >
                  <div className="lang-content">
                    <Icon />
                    <span>{t(`sidebar:${label}`)}</span>
                  </div>
                  <RightArrow />
                </div>
              );
            })}
          </div>
        </ModalContent>
      </CentralizedModal>
    </>
  );
}

export default SidebarItem;
