import { faExclamation, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import PropTypes from "prop-types";
import React, { useEffect, useState, useRef, Fragment } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Select from "react-select";
import styled from "styled-components";
import * as yup from "yup";
import ContainerFluid from "components/containers/container-fluid";
import EventAutocomplte from "components/inputeventautocomplete";
import SideModal from "components/side-modal";
import SuperRadioButton from "components/super-radiobutton";
import { useLanguageSettings } from "hooks/language-settings";
import { cpfMask, numberMask, phoneMask } from "mask";
import Api from "services/api";
import { destinyKeys, finalityKeys } from "services/constants";
import { IpService } from "services/ip";
import New_api from "services/new_api";
import { ValidationsHelper } from "services/validations";
import { ReactComponent as Store } from "../../../assets/icons/store.svg";
import "./styles.css";
import { ReactComponent as Upload } from "../../../assets/icons/upload.svg";
import { ReactComponent as FileArrowUp } from "../../../assets/icons/fileArrowUp.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/DownloadSimple.svg";
import { ReactComponent as TrashCan } from "../../../assets/icons/trashCan.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/X.svg";
import { parsedNumber } from "utils";

const documentJs = document;

const transferFormSchema = (isAdmin, available) => {
  return yup.object({
    method: yup
      .string()
      .default("PIX")
      .oneOf(["PIX", "TED"])
      .required("A forma de transferência deve ser informada."),
    name: yup.string().trim().required("O destinatário deve ser informado."),
    observation: yup
      .string()
      .trim()
      .max(1024, "A observação deve ter no máximo 1024 caracteres."),
    amount: yup
      .string()
      .required("O valor deve ser informado.")
      .test(
        "min",
        "O valor deve ser maior que 0.",
        (val) => parsedNumber(val) > 0
      ),
    justificationDocumentLink: !isAdmin
      ? yup
          .string()
          .when("amount", (value, field) =>
            parsedNumber(value) > available
              ? field.required(
                  "É obrigatório o envio de um documento para solicitar a transferência."
                )
              : field.notRequired()
          )
      : yup.string().notRequired(),
    type_account: yup
      .string()
      .when("method", (method, field) =>
        method === "PIX"
          ? field
              .required("O tipo de chave deve ser informado.")
              .oneOf(["email", "phone", "key", "document"])
          : field
              .required("O tipo da conta deve ser informado.")
              .oneOf(["savings", "checking"])
      ),
    document: yup
      .string()
      .trim()
      .required("O documento deve ser informado.")
      .test(
        "documentTest",
        "Informe um documento válido.",
        (value) =>
          value &&
          (ValidationsHelper.isCpfValid(value) ||
            ValidationsHelper.isCnpjValid(value))
      ),
    bank_id: yup
      .string()
      .when("method", (method, field) =>
        method === "PIX" ? field : field.required("O banco deve ser informado.")
      ),
    agency: yup
      .string()
      .trim()
      .when("method", (method, field) =>
        method === "PIX"
          ? field
          : field.required("A agência deve ser informada.")
      ),
    account: yup
      .string()
      .trim()
      .when("method", (method, field) =>
        method === "PIX"
          ? field
          : field.required("O conta bancária deve ser informada.")
      ),
    account_digit: yup
      .string()
      .trim()
      .when("method", (method, field) =>
        method === "PIX"
          ? field
          : field.required("O dígito deve ser informado.")
      ),
    origin: isAdmin
      ? yup
          .string()
          .required("A origem da transferência deve ser informada.")
          .oneOf(["ticket", "a&b"])
      : yup.string().notRequired(),
    category: isAdmin
      ? yup
          .string()
          .required("A categoria da transferência deve ser informada.")
          .oneOf(["advance", "transfer"])
      : yup.string().notRequired(),
    finality: yup
      .string()
      .required("A finalidade da transferência deve ser informada.")
      .oneOf([
        "artist",
        "air",
        "food",
        "marketing",
        "bar",
        "structure",
        "operation",
        "online_anticipation",
        "pos_anticipation",
      ]),
    destiny: yup
      .string()
      .oneOf(["organizer", "third party"])
      .required("O destino de conta é obrigatório."),
    pix_key: yup
      .string()
      .trim()
      .when("type_account", (type, field) => {
        if (type === "key") {
          return field.required("A chave deve ser informada.");
        } else if (type === "phone") {
          return field
            .required("O telefone deve ser informado.")
            .matches(
              /^\((\d{2})\) \D*(\d{5}|\d{4})\D*(\d{4})$/,
              "Deve ser informado um telefone válido."
            );
        } else if (type === "email") {
          return field
            .email("Deve ser informado um e-mail válido.")
            .required("O e-mail deve ser informado.");
        } else if (type === "document") {
          return field
            .required("O documento deve ser informado.")
            .test(
              "documentTest",
              "Informe um documento válido.",
              (value) =>
                value &&
                (ValidationsHelper.isCpfValid(value) ||
                  ValidationsHelper.isCnpjValid(value))
            );
        }
      }),
  });
};

const pixKeys = {
  document: "CPF ou CNPJ",
  email: "E-mail",
  phone: "Celular",
  key: "Chave aleatória",
};

const originKeys = {
  ticket: "Ticket",
  "a&b": "A&B",
};

const categoryKeys = {
  advance: "Adiantamento",
  transfer: "Transferência",
};

const pixKeyPlaceholder = {
  phone: "Telefone com DDD",
  email: "email@provedor.com.br",
  key: "Insira a chave aleatória",
  document: "Insira um CPF ou CNPJ",
};

function ValueField(props) {
  const { register, error, onChange } = props;

  return (
    <Form.Group as={Col} md="6">
      <Form.Label>Valor</Form.Label>
      <Form.Control
        {...register("amount")}
        isInvalid={error}
        placeholder="Valor da transferência"
        onChange={(e) => onChange(e)}
      />
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Group>
  );
}

function DocumentField({
  title,
  register,
  placeholder,
  error,
  onChange,
  value,
}) {
  return (
    <Form.Group as={Col} md="6">
      <Form.Label>{title}</Form.Label>
      <CpfCnpj
        {...register("document")}
        className={`form-control ${error ? "is-invalid" : ""}`}
        placeholder={placeholder || "Insira um CPF ou CNPJ"}
        value={value}
        onChange={(e) => onChange(e)}
      />
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Group>
  );
}

CreateTransferModal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  eventId: PropTypes.number,
  maxValue: PropTypes.number,
  eventName: PropTypes.string,
  onError: PropTypes.func,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  formatCurrency: PropTypes.func,
  defaultEventOrgInfo: PropTypes.object,
};

export default function CreateTransferModal(props) {
  const fileInputRef = useRef(null);
  const { currencyInputFormatter } = useLanguageSettings();
  const {
    show,
    title,
    //maxValue,
    eventName,
    eventId,
    onClose,
    onError,
    onSuccess,
    currency,
    formatCurrency,
    defaultEventOrgInfo,
  } = props;
  const [banks, setBanks] = useState([]);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orgInfoLoading, setOrgInfoLoading] = useState(false);
  const [customEvent, setCustomEvent] = useState("");
  const [customEventId, setCustomEventId] = useState(eventId);
  const [organizationInfo, setOrganizationInfo] = useState(null);
  const [availableToTransfer, setAvailableToTransfer] = useState(0);
  const [justificationFile, setJustificationFile] = useState(false);
  const [file, setFile] = useState(null);
  const [justificationFileError, setJustificationFileError] = useState(false);
  const [justificationFileLoading, setJustificationFileLoading] =
    useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  const availableToTransferValue = () => {
    if (defaultEventOrgInfo?.available > 0) {
      return defaultEventOrgInfo?.available
    }

    if (organizationInfo?.available > 0) {
      return organizationInfo?.available
    } else {
      return 0
    }
  }

  const {
    reset,
    watch,
    register,
    setValue,
    resetField,
    clearErrors,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(
      transferFormSchema(
        !!user.is_admin,
        Number(availableToTransferValue()).toFixed(2)
      )
    ),
  });
  const amount = watch("amount");
  const method = watch("method");
  const pixKey = watch("type_account");
  const document = watch("document");

  const handleSelectEvent = ({ id }) => {
    setOrgInfoLoading(true);
    setCustomEventId(id);
    New_api.get(`transfers/organization-info/${id}`)
      .then(({ data }) => setOrganizationInfo(data))
      .catch((error) => console.error(error))
      .finally(() => setOrgInfoLoading(false));
  };

  const selectBank = (e) => setValue("bank_id", e.value);

  const handleClose = () => {
    if (!loading) {
      setJustificationFile(false);
      setFile(null);
      setJustificationFileError(false);
      setJustificationFileLoading(false);
      setOrganizationInfo(null);
      onClose();
    }
  };

  const handleFormData = async (form) => {
    setLoading(true);

    let ip;

    try {
      ip = await IpService.getMyIp();
    } catch {}

    let data = {
      ...form,
      amount: numberMask(form.amount),
      ip,
    };

    if (data.method === "PIX") {
      data = {
        ...data,
        agency: null,
        account: null,
        account_digit: null,
        bank_id: null,
      };
    }

    if (!user.is_admin) {
      data = {
        ...data,
        origin: null,
        category: "transfer",
      };
    }

    New_api.post(`/events/${eventId || customEventId}/transfers`, data)
      .then((result) => {
        onSuccess(result.data);
        onClose();
      })
      .catch((err) => onError(err))
      .finally(() => setLoading(false));
  };

  const handleDocumentValue = (event) => {
    let value = event.target.value;

    if (pixKey === "phone") {
      value = phoneMask(value);
    }

    if (pixKey === "document") {
      value = cpfMask(value);
    }

    setValue("pix_key", value);
  };

  const _fetchBanks = () => {
    Api.get("lista/bancos")
      .then((res) => {
        const data = res.data.map((item) => ({
          value: item.id,
          name: item.code + " - " + item.name,
        }));

        setBanks(data);
      })
      .catch(() => setBanks([]));
  };

  useEffect(() => {
    clearErrors();

    if (method) {
      setValue("type_account", method === "PIX" ? "document" : "checking");
      resetField("document");
    }
  }, [method, clearErrors, resetField, setValue]);

  useEffect(() => {
    reset({
      method: "PIX",
      type_account: "document",
      origin: "",
      category: "",
      finality: "",
      observation: "",
    });
    setChecked(false);
    setCustomEvent("");
    setCustomEventId(null);
    setAvailableToTransfer(0)

    if (show) {
      _fetchBanks();
      setAvailableToTransfer(availableToTransferValue());
    }
    // eslint-disable-next-line
  }, [show, reset]);

  const handleDivJustificationClick = () => fileInputRef.current.click();

  const handleJustificationFile = (event) => {
    setJustificationFileLoading(true);
    const form_data = new FormData();
    const file_data = event.target.files[0];
    form_data.append("image", file_data);
    const maxFileSize = 1 * 1024 * 1024;
    setFile(file_data);

    if (file_data?.size > maxFileSize) {
      setJustificationFileLoading(false);
      justificationFileCanceled();
      setError("justificationDocumentLink", {
        type: "custom",
        message: "O tamanho máximo do arquivo não deve ultrapassar 1MB.",
      });
      return;
    }

    Api.post("uploads?local=transferencias", form_data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then(({ data: { url } }) => {
        setJustificationFile(true);
        if (errors?.justificationDocumentLink?.message) {
          clearErrors("justificationDocumentLink");
        }
        setValue("justificationDocumentLink", url);
      })
      .catch(() => {
        setFile(null);
        setJustificationFileError(true);
      })
      .finally(() => setJustificationFileLoading(false));
  };

  const justificationFileCanceled = () => {
    fileInputRef.current.value = "";
    setJustificationFile(false);
    setFile(null);
    setJustificationFileError(false);
    setValue("justificationDocumentLink", "");
  };

  const handleDownloadFileClick = () => {
    try {
      if (file) {
        const href = URL.createObjectURL(file);
        const link = documentJs.createElement("a");
        link.href = href;
        link.setAttribute("download", file.name);
        documentJs.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(href);
        documentJs.body.removeChild(link);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <SideModal show={show} title={title.toUpperCase()} onClose={handleClose}>
      <ContainerFluid>
        <Row>
          <Col>
            <Alert variant="danger" className="mt-3 w-100">
              <span className="icon-circle-sm">
                <FontAwesomeIcon icon={faExclamation} />
              </span>
              {eventName ? (
                <span>
                  <b>Atenção!</b> Você está transferindo através dos recursos do
                  evento <b>{eventName}</b>.
                </span>
              ) : (
                <span>
                  <b>Atenção!</b> Você está transferindo através dos recursos{" "}
                  <b> da conta gestora</b>.
                </span>
              )}
            </Alert>
          </Col>
        </Row>
        <Form noValidate onSubmit={handleSubmit(handleFormData)}>
          {!eventId && (
            <Form.Row>
              <Form.Group as={Col} md="12" className="mb-0">
                <EventAutocomplte
                  value={customEvent}
                  onChange={setCustomEvent}
                  onSelect={handleSelectEvent}
                />
              </Form.Group>
            </Form.Row>
          )}

          {!!user.is_admin &&
            (orgInfoLoading || organizationInfo || defaultEventOrgInfo) && (
              <div className="organization-info-container d-flex align-items-center mt-3 mb-3">
                {orgInfoLoading ? (
                  <div className="d-flex w-100 flex-column align-items-center justify-content-center">
                    <span
                      className="spinner-border spinner-border-sm text-primary mx-1"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="organization-info-label mt-1">
                      Carregando informações da organização
                    </span>
                  </div>
                ) : (
                  <Fragment>
                    <div className="organization-info-icon">
                      <Store />
                    </div>
                    <Row>
                      <div className="organization-info-content d-flex flex-column col-md-8 col-sm-12 mb-2">
                        <span className="organization-info-label">
                          Organização responsável
                        </span>
                        <span className="organization-info-data">
                          {organizationInfo?.organization?.name ||
                            defaultEventOrgInfo?.organization?.name}
                        </span>
                      </div>
                      <div className="d-flex flex-column col-md-4 col mb-2">
                        <span className="organization-info-label">
                          Nível de confiança
                        </span>
                        <span className="organization-info-data">
                          {organizationInfo?.organization?.trustLevel?.name ||
                            defaultEventOrgInfo?.organization?.trustLevel?.name}
                        </span>
                      </div>
                      <div className="d-flex flex-column col-md-4 col">
                        <span className="organization-info-label">CNPJ</span>
                        <span className="organization-info-data">
                          {organizationInfo?.organization?.document ||
                            defaultEventOrgInfo?.organization?.document ||
                            "-"}
                        </span>
                      </div>
                      <div className="d-flex flex-column col-md-4 col organization-owner-style">
                        <span className="organization-info-label">
                          Responsável
                        </span>
                        <span className="organization-info-data">
                          {`${
                            organizationInfo?.user?.first_name ||
                            defaultEventOrgInfo?.user?.first_name
                          } ${
                            organizationInfo?.user?.last_name ||
                            defaultEventOrgInfo?.user?.last_name
                          }`}
                        </span>
                      </div>
                      <div className="d-flex flex-column col-md-4 col">
                        <span className="organization-info-label">CPF</span>
                        <span className="organization-info-data">
                          {organizationInfo?.organization?.owner_document ||
                            defaultEventOrgInfo?.organization?.owner_document}
                        </span>
                      </div>
                    </Row>
                  </Fragment>
                )}
              </div>
            )}
          {/* {customEventId && <hr />} */}
          {(eventId ||
            (customEventId && organizationInfo) ||
            defaultEventOrgInfo) && (
            <>
              <Form.Row className="mb-3 ml-1 mt-3">
                <div className="col-md-3 col-6 value-style-container">
                  <div className="d-flex flex-column">
                    <span className="value-style-label mb-2">
                      Disponível para transferir
                    </span>
                    <span className="value-style">
                      {formatCurrency(
                        availableToTransfer,
                        {
                          currency,
                          isCents: true,
                        }
                      )}
                    </span>
                  </div>
                </div>
              </Form.Row>

              <Form.Row className="mb-4">
                <Form.Group as={Col} md="2" className="col-6 mb-0">
                  <SuperRadioButton
                    space={true}
                    requiredValue="PIX"
                    value={method}
                    label="PIX"
                    callback={() => setValue("method", "PIX")}
                  />
                </Form.Group>
                <Form.Group as={Col} md="2" className="col-6 mb-0">
                  <SuperRadioButton
                    space={true}
                    value={method}
                    requiredValue="TED"
                    label="TED"
                    callback={() => setValue("method", "TED")}
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md="12">
                  <Form.Label>
                    {method === "PIX" ? "Destinatário" : "Nome completo"}
                  </Form.Label>
                  <Form.Control
                    {...register("name")}
                    placeholder={
                      method === "PIX"
                        ? "Nome completo ou descrição"
                        : "Nome completo"
                    }
                    isInvalid={errors.name?.message}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                {method === "PIX" && (
                  <ValueField
                    register={register}
                    error={errors.amount?.message}
                    onChange={(e) =>
                      setValue(
                        "amount",
                        currencyInputFormatter(e.target.value, currency)
                      )
                    }
                  />
                )}
                <DocumentField
                  title="Documento do destinatário"
                  register={register}
                  value={document}
                  error={errors.document?.message}
                  onChange={(e) => setValue("document", e.target.value)}
                />
              </Form.Row>

              {(Number(defaultEventOrgInfo?.pre_approved / 100).toFixed(2) ||
                Number(organizationInfo?.pre_approved / 100).toFixed(2)) <
                parsedNumber(amount) && (
                  <div className="col-12 pl-0 my-2">
                    <span className="justification-file-label">
                    Para utilização do saldo disponível acima do valor pré-aprovado, anexe alguma evidência da finalidade do pagamento (contrato ou documento) para análise.
                    </span>
                    <div className="col-12 mt-1">
                      <div className="row">
                        <div className="col-2 col-md-1 p-0">
                          <div
                            style={{
                              backgroundColor: justificationFileError
                                ? "#FFE1D7"
                                : "#F5F5F7",
                            }}
                            className="icon-container"
                          >
                            {justificationFile || justificationFileError ? (
                              <FileArrowUp
                                fill={
                                  justificationFileError ? "red" : "#1A212B"
                                }
                              />
                            ) : justificationFileLoading ? (
                              <div
                                class="spinner-border text-primary"
                                style={{ width: "1.5rem", height: "1.5rem" }}
                                role="status"
                              ></div>
                            ) : (
                              <Upload />
                            )}
                          </div>
                        </div>
                        <div className="col-10 col-md-11 p-0">
                          <div className="col-12">
                            <div className="row">
                              <div
                                className={
                                  justificationFile || justificationFileError
                                    ? "col-9"
                                    : "col-12"
                                }
                              >
                                <div className="d-flex flex-column">
                                  <div className="text-link-label col-12  p-0 m-0">
                                    {file && !justificationFileLoading
                                      ? file?.name
                                      : "Anexar documento (JPEG, PNG ou PDF)"}
                                  </div>
                                  {justificationFileError ? (
                                    <div className="col-12 m-0 p-0">
                                      <span className="text-danger">
                                        <small style={{ color: "#dc3545" }}>
                                          Erro ao adicionar arquivo
                                        </small>
                                      </span>
                                    </div>
                                  ) : justificationFile ? (
                                    <div className="col-12 col-md-6 p-0 m-0">
                                      {`${(file?.size / 1024).toFixed(2)} KB`}
                                    </div>
                                  ) : (
                                    <div
                                      name="addJustificationDocumentButton"
                                      onClick={handleDivJustificationClick}
                                      className="text-link-file col-12 col-md-6 p-0 m-0"
                                    >
                                      Adicione aqui
                                    </div>
                                  )}
                                </div>
                              </div>
                              {(justificationFile ||
                                justificationFileError) && (
                                <div className="col-3">
                                  <div className="row h-100 align-items-center justify-content-end">
                                    {justificationFile ? (
                                      <Fragment>
                                        <div className="mr-2">
                                          <button
                                            type="button"
                                            onClick={handleDownloadFileClick}
                                            style={{
                                              backgroundColor: "transparent",
                                              border: "none",
                                            }}
                                          >
                                            <DownloadIcon fill="none" />
                                          </button>
                                        </div>
                                        <div>
                                          <button
                                            type="button"
                                            onClick={justificationFileCanceled}
                                            style={{
                                              backgroundColor: "transparent",
                                              border: "none",
                                            }}
                                          >
                                            <TrashCan stroke="#171719" />
                                          </button>
                                        </div>
                                      </Fragment>
                                    ) : (
                                      <div>
                                        <button
                                          type="button"
                                          onClick={justificationFileCanceled}
                                          style={{
                                            backgroundColor: "transparent",
                                            border: "none",
                                          }}
                                        >
                                          <CloseIcon />
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <input
                      type="file"
                      ref={fileInputRef}
                      accept=".pdf, .jpg, .jpeg, .png"
                      className="d-none"
                      onChange={handleJustificationFile}
                    />
                    {errors.justificationDocumentLink?.message && (
                      <div className="col-12">
                        <span className="text-danger">
                          <small style={{ color: "#dc3545" }}>
                            {errors.justificationDocumentLink?.message}
                          </small>
                        </span>
                      </div>
                    )}
                  </div>
                )}

              {method === "PIX" ? (
                <Form.Row>
                  <Form.Group as={Col} md="6">
                    <Form.Label>Tipo da Chave</Form.Label>
                    <Form.Control
                      as="select"
                      {...register("type_account")}
                      isInvalid={errors.type_account?.message}
                    >
                      <option value="" disabled>
                        Selecione o tipo da chave
                      </option>
                      {Object.keys(pixKeys).map((key) => (
                        <option key={key} value={key}>
                          {pixKeys[key]}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.type_account?.message}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="6">
                    <Form.Label>Chave PIX</Form.Label>
                    <Form.Control
                      {...register("pix_key")}
                      placeholder={pixKeyPlaceholder[pixKey]}
                      isInvalid={errors.pix_key?.message}
                      onChange={handleDocumentValue}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.pix_key?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
              ) : (
                <>
                  <Form.Row>
                    <Form.Group as={Col} md="6">
                      <Form.Label>Banco</Form.Label>
                      <Select
                        options={banks.map((bank) => ({
                          value: bank.value,
                          label: bank.name,
                        }))}
                        onChange={selectBank}
                        placeholder="Banco"
                        isInvalid={errors.bank_id?.message}
                      />
                      {errors.bank_id?.message && (
                        <ErrorFont className="text-danger mt-1">
                          É necessário selecionar um banco.
                        </ErrorFont>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                      <Form.Label>Tipo da Conta</Form.Label>
                      <Form.Control
                        as="select"
                        {...register("type_account")}
                        isInvalid={errors.type_account?.message}
                      >
                        <option value="" disabled>
                          Selecione o tipo da conta
                        </option>
                        <option value="checking">Corrente</option>
                        <option value="savings">Poupança</option>
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.type_account?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="2">
                      <Form.Label>Agência</Form.Label>
                      <Form.Control
                        {...register("agency")}
                        maxLength="4"
                        placeholder="Sem dígito"
                        isInvalid={errors.agency?.message}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.agency?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="3" className="col-8">
                      <Form.Label>Conta</Form.Label>
                      <Form.Control
                        {...register("account")}
                        placeholder="Sem dígito"
                        type="number"
                        isInvalid={errors.account?.message}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.account?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="1" className="col-4">
                      <Form.Label>Dígito</Form.Label>
                      <Form.Control
                        {...register("account_digit")}
                        maxLength="1"
                        isInvalid={errors.account_digit?.message}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.account_digit?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <ValueField
                      register={register}
                      error={errors.amount?.message}
                      onChange={(e) =>
                        setValue(
                          "amount",
                          currencyInputFormatter(e.target.value, currency)
                        )
                      }
                    />
                  </Form.Row>
                </>
              )}
              {eventName ? null : (
                <>
                  <hr></hr>
                  <Form.Row>
                    <Form.Group as={Col} md={6}>
                      <Form.Label>Categoria</Form.Label>
                      <Form.Control
                        as="select"
                        {...register("category")}
                        isInvalid={errors.category?.message}
                      >
                        <option value="" disabled>
                          Selecione a categoria da transferência
                        </option>
                        {Object.keys(categoryKeys).map((key) => (
                          <option key={key} value={key}>
                            {categoryKeys[key]}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.category?.message}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md={6}>
                      <Form.Label>Origem</Form.Label>
                      <Form.Control
                        as="select"
                        {...register("origin")}
                        isInvalid={errors.origin?.message}
                      >
                        <option value="" disabled>
                          Selecione a origem da transferência
                        </option>
                        {Object.keys(originKeys).map((key) => (
                          <option key={key} value={key}>
                            {originKeys[key]}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.origin?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                </>
              )}

              <Form.Row>
                <Form.Group as={Col} md={6}>
                  <Form.Label>Finalidade</Form.Label>
                  <Form.Control
                    as="select"
                    {...register("finality")}
                    isInvalid={errors.finality?.message}
                  >
                    <option value="" disabled>
                      Selecione a finalidade da transferência
                    </option>
                    {Object.keys(finalityKeys).map((key) => (
                      <option key={key} value={key}>
                        {finalityKeys[key]}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.finality?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <Form.Label>Destino</Form.Label>
                  <Form.Control
                    as="select"
                    {...register("destiny")}
                    isInvalid={errors.destiny?.message}
                  >
                    <option value="">
                      Selecione o destino da transferência
                    </option>
                    {Object.keys(destinyKeys).map((key) => (
                      <option key={key} value={key}>
                        {destinyKeys[key]}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.destiny?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md={12}>
                  <Form.Label>Observação</Form.Label>
                  <Form.Control
                    style={{ resize: "none" }}
                    {...register("observation")}
                    name="observation"
                    autoComplete="nope"
                    type="textarea"
                    as="textarea"
                    rows={3}
                    placeholder="Observação da transferência"
                    isInvalid={errors.observation?.message}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.observation?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              <TermsContainer
                name="termsCheckbox"
                onClick={() => setChecked(!checked)}
              >
                <Row noGutters>
                  <Col xs="2" md="1" className="d-flex justify-content-center">
                    <TermsCheckbox accepted={checked}>
                      <TermsCheckmark show={checked}>
                        <TermsCheckmarkStem></TermsCheckmarkStem>
                        <TermsCheckmarkKick></TermsCheckmarkKick>
                      </TermsCheckmark>
                    </TermsCheckbox>
                  </Col>
                  <Col md="10">
                    <TermsTitle>
                      Ao prosseguir com essa solicitação de transferência, eu
                      declaro concordar com os{" "}
                      <TermsLink target="_blank" to="/termos-de-uso">
                        termos de uso da plataforma.
                      </TermsLink>
                    </TermsTitle>
                    <TermsSubtitle>
                      Essa transferência, bem como o destino dado a ela,
                      conforme descrito nos termos de uso, é de inteira
                      responsabilidade do solicitante, assim como o recolhimento
                      de tributos relacionados à receita de vendas do site e
                      responsabilidade com as leis anti corrupção.
                    </TermsSubtitle>
                  </Col>
                </Row>
              </TermsContainer>

              <Row className="justify-content-end mt-3 mb-3">
                <Col md="5">
                  <Button
                    id="createTransfer"
                    variant="secondary"
                    type="submit"
                    disabled={!checked || loading}
                  >
                    {loading ? (
                      "Enviando"
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faFileAlt} className="mr-2" />
                        {" " + title}
                      </>
                    )}
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </ContainerFluid>
    </SideModal>
  );
}

//------- Styled Components ------//
const TermsContainer = styled.div`
  border: 2px solid #e6f0f6;
  border-radius: 8px;
  padding: 16px 0;
  background: #f1f9f9;
`;

const TermsTitle = styled.p`
  font-size: 13px;
  color: #4f6c7c;
  margin: 0 0 5px;
  font-weight: 400;
`;

const TermsSubtitle = styled.p`
  font-weight: 300;
  color: #8fa5b2;
  font-size: 11px;
  margin: 0;
`;

const TermsCheckmark = styled.span`
  display: ${(props) => (props.show ? "inline-block" : "none")};
  width: 38px;
  height: 9px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
`;

const TermsCheckmarkStem = styled.div`
  position: absolute;
  width: 3px;
  height: 12px;
  background-color: #ffffff;
  left: 9px;
  top: 3px;
`;

const TermsCheckmarkKick = styled.div`
  position: absolute;
  width: 6px;
  height: 3px;
  background-color: #ffffff;
  left: 4px;
  top: 12px;
`;

const TermsCheckbox = styled.div`
  width: 19px;
  height: 19px;
  border: 2px solid rgb(79 108 124 / 59%);
  border-radius: 3px;
  margin: 5px 0;
  cursor: pointer;
  background-color: ${(props) => (props.accepted ? "#00a7e5" : "none")};
  border-color: ${(props) =>
    props.accepted ? "#00a7e5" : "rgb(79 108 124 / 59%)"};
`;

const TermsLink = styled(Link)`
  text-decoration: underline;
  color: #2075f4;
`;

const ErrorFont = styled.span`
  font-size: 13px;
`;
