/* eslint-disable import/no-cycle */
import React, { useEffect } from "react";
import { Redirect, Switch, useHistory } from "react-router-dom";

import EventBar from "components/createEventAeB";
import useQuery from "customerHooks/useQuery";
import PageError from "pages/404";
import BarConfig from "pages/bar-area/bar-config";
import CategoriesReport from "pages/bar-area/bar-reports/event-categories-report";
import EventReport from "pages/bar-area/bar-reports/event-report";
import ReportCategory from "pages/bar-area/bar-reports/event-report-category";
import EstablishmentSold from "pages/bar-area/establishments-sold";
import Commands from "pages/bar-area/event-commands";
import EventComandDaily from "pages/bar-area/event-commands-daily";
import EventDashboard from "pages/bar-area/event-dashboard";
import EventMenuNew from "pages/bar-area/event-menu-new";
import EventStockNew from "pages/bar-area/event-stock-new";
import EventStockTrans from "pages/bar-area/event-stock-new/event-stock-trans";
import Sector from "pages/bar-area/sector";
import EditSector from "pages/bar-area/sector/edit-sector";
import RegisterSector from "pages/bar-area/sector/register-sector";
import AntiFraud from "pages/common-area/audit/antiFraud";
import Emails from "pages/common-area/audit/emails";
import SalesTickets from "pages/common-area/audit/salesTickets/index";
import EquipmentControl from "pages/common-area/equipments/equipment-control";
import Inventory from "pages/common-area/equipments/inventory";
import MyEquipments from "pages/common-area/equipments/my-equipments";
import Establishiments from "pages/common-area/establishments";
import RegisterEstablishments from "pages/common-area/establishments/register-establishment";
import Events from "pages/common-area/events";
import DetalhesCancelamentoEvento from "pages/common-area/events/event-cancel-details";
import EventCreate from "pages/common-area/events/event-create";
import EventEdit from "pages/common-area/events/event-edit";
import CancelamentoEventos from "pages/common-area/events/events-cancel";
import Faq from "pages/common-area/faq";
import Faqs from "pages/common-area/faqs";
import FinanceiroProdutor from "pages/common-area/financeiro-dashboard";
import PlanosPagamentos from "pages/common-area/financeiro-planos";
import FinanceiroTransferencias from "pages/common-area/financeiro-transferencias";
import SeatsListing from "pages/common-area/seats-chart-list";
import SeatsMapCreate from "pages/common-area/seats-map/seats-map-create";
import SeatsMapEdit from "pages/common-area/seats-map/seats-map-edit";
import EventCategorization from "pages/common-area/setup/event-categorization";
import PdvsManager from "pages/common-area/setup/pdvs-manager";
import Personalizations from "pages/common-area/setup/personalizations";
import TicketCategory from "pages/common-area/setup/ticket-categories";
import TermosUso from "pages/common-area/termos";
import ListTerms from "pages/common-area/termos/gestao-termos-de-uso";
import RegisterTerms from "pages/common-area/termos/registro-termos";
import TicketEventTransform from "pages/common-area/ticketEventTransform";
import Example from "pages/example";
import FilterCaixa from "pages/filterCaixa";
import RegisterPDV from "pages/pdv";
import PdvList from "pages/pdv/pdvList";
import GeneralReport from "pages/reports/general-report";
import InternalReports from "pages/reports/internal-reports";
import Checkin from "pages/ticket-area/checkin";
import EventSalesDate from "pages/ticket-area/event-sales-date";
import Comissarios from "pages/ticket-area/ingressos/comissarios";
import Courtesies from "pages/ticket-area/ingressos/courtesies";
import Ingressos from "pages/ticket-area/ingressos/ingressosHooks";
import Localizadores from "pages/ticket-area/ingressos/localizadores";
import Passports from "pages/ticket-area/ingressos/passports";
import PromocionalCode from "pages/ticket-area/ingressos/promocionalCode/index";
import SortingOfSectors from "pages/ticket-area/ingressos/sortingOfSectors";
import { Participantes } from "pages/ticket-area/participantes";
import CameraCheckin from "pages/ticket-area/participantes/cameraCheckin";
import EventSeatsMaps from "pages/ticket-area/seats-maps";
import VendasPDV from "pages/ticket-area/vendas/vendasPDV";
import VendasComissarios from "pages/ticket-area/vendascomissarios";
import api from "services/api";
import { PERMISSIONAB } from "services/constants";

import CreateEventHelp from "./components/cadastroevento/create-help";
import Login from "./pages/auth-area/login";
import LoginComplete from "./pages/auth-area/login-complete";
import RecuperarSenha from "./pages/auth-area/recuperar-senha";
import Register from "./pages/auth-area/register";
import CadastroAssentos from "./pages/common-area/cadastroassentos";
import EquipmentsList from "./pages/common-area/equipments/equipment-list";
import EquipmentsTypes from "./pages/common-area/equipments/equipment-types";
import CreateEvent from "./pages/common-area/events/create-event";
import CadastroIngresso from "./pages/common-area/events/create-event/cadastroIngresso";
import Organizacoes from "./pages/common-area/organizacoes";
import Organizacao from "./pages/common-area/organizacoes/organizacao";
import Products from "./pages/common-area/products";
import ProductsCategories from "./pages/common-area/products-categories";
import DashboardMaster from "./pages/reports/dashboard-master";
import Eventodetalhes from "./pages/ticket-area/eventodetalhe";
import UserEdit from "./pages/ticket-area/user-edit";
import Vendas from "./pages/ticket-area/vendas";
import UserData from "./pages/user-area/user-data";
import Users from "./pages/user-area/users";
import RegisterUser from "./pages/user-area/users/register-user";
import Integrations from "./pages/integrationsPage";
import Route from "./services/auth";
import AudienceAndVisits from "pages/ticket-area/audienceAndVisits";
import { Bordero } from "pages/common-area/bordero";
import Preferences from "pages/common-area/setup/preferences";
import NominalTransfers from "pages/ticket-area/nominal-transfer";
import AuditDetailedSales from "pages/common-area/audit/sales";

function isAuthenticated(checkComplete = false) {
  const user = JSON.parse(localStorage.getItem("user") || null);
  const token = localStorage.getItem("token") || null;

  if (!!user && window.clarity) {
    window.clarity(
      "set",
      "user_type",
      user.is_admin ? "backoffice" : "producer"
    );
  }

  return (
    user &&
    token &&
    (user.is_admin ||
      (checkComplete && user.complete_register === 1) ||
      !checkComplete)
  );
}

function PrivateRoute(props) {
  return isAuthenticated(true) ? (
    <Route {...props} />
  ) : (
    <Redirect to={{ pathname: "/sign-in" }} />
  );
}

export default function Routes() {
  const history = useHistory();
  const query = useQuery();
  const eventid = query.get("event_id");

  useEffect(() => {
    if (!isAuthenticated()) {
      history.push("/sign-in");
    }
  }, [history]);

  const PrivateABRoute = (props) => {
    useEffect(() => {
      if (eventid) {
        api.get(`event/verifyAB/${eventid}`).then((response) => {
          const hasAB = response.data;
          if (!hasAB) {
            history.push("/events");
          }
        });
      } else if (!PERMISSIONAB) {
        history.push("/events");
      }
      // eslint-disable-next-line
    }, [eventid]);

    return <Route {...props} />;
  };

  return (
    <Switch>
      <Route exact path="/sign-in" component={Login} />

      {/* -------------------------------- AUTH AREA  ----------------------------- */}
      <PrivateABRoute path="/event/create-aeb" component={EventBar} isPrivate />
      <Route exact path="/complete/login" component={LoginComplete} />
      <Route path="/register" component={Register} isPrivate />

      {/* -------------------------------- COMMON AREA ----------------------------- */}
      <PrivateRoute path="/eventos" exact component={Events} isPrivate />
      <PrivateRoute path="/example" component={Example} isPrivate />
      <PrivateRoute path="/generalReport" component={GeneralReport} isPrivate />
      <PrivateRoute
        path="/internal-reports"
        component={InternalReports}
        isPrivate
      />
      <PrivateRoute
        path="/evento/ingressos"
        component={CadastroIngresso}
        isPrivate
      />
      <PrivateRoute
        path="/evento/lugares"
        component={CadastroAssentos}
        isPrivate
      />
      <PrivateRoute path="/dashboard" component={DashboardMaster} isPrivate />
      <PrivateRoute
        path="/personalizations"
        component={Personalizations}
        isPrivate
      />
      <PrivateRoute path="/organizacoes" component={Organizacoes} isPrivate />
      <PrivateRoute path="/mapas" component={SeatsListing} isPrivate exact />
      <PrivateRoute
        path="/mapas/novo"
        component={SeatsMapCreate}
        isPrivate
        exact
      />
      <PrivateRoute path="/mapas/:id" component={SeatsMapEdit} isPrivate />
      <PrivateRoute path="/organizacao" component={Organizacao} isPrivate />
      <PrivateRoute
        exact
        path="/novo-evento"
        component={CreateEvent}
        isPrivate
      />
      <PrivateRoute
        exact
        path="/evento-help"
        component={CreateEventHelp}
        isPrivate
      />
      <PrivateRoute
        path="/equipments/equipment-list"
        component={EquipmentsList}
        isPrivate
      />
      <PrivateRoute path="/products" component={Products} isPrivate />
      <PrivateRoute
        path="/products-categories"
        component={ProductsCategories}
        isPrivate
      />
      <PrivateRoute
        path="/equipments/equipment-types"
        component={EquipmentsTypes}
        isPrivate
      />
      <PrivateRoute path="/validatoken" component={RecuperarSenha} isPrivate />
      <PrivateRoute
        path="/financeiro-dashboard"
        component={FinanceiroProdutor}
        isPrivate
      />
      <PrivateRoute
        path="/financeiro-transferencias"
        component={FinanceiroTransferencias}
        isPrivate
      />
      <PrivateRoute
        path="/tickets/financeiro-transferencias"
        component={FinanceiroTransferencias}
        isPrivate
      />
      <PrivateRoute
        path="/tickets/financeiro-bordero"
        component={Bordero}
        isPrivate
      />
      <PrivateRoute
        path="/equipments/equipment-control"
        component={EquipmentControl}
        isPrivate
      />
      <PrivateRoute path="/faqs" component={Faqs} isPrivate />
      <PrivateRoute path="/faq/:id?" component={Faq} isPrivate />
      <PrivateRoute
        path="/establishments"
        component={Establishiments}
        isPrivate
      />
      <PrivateRoute
        path="/register-establishment/:id?"
        component={RegisterEstablishments}
        isPrivate
      />
      <PrivateRoute path="/termos-de-uso" component={TermosUso} isPrivate />
      <PrivateRoute
        exact
        path="/eventos/cancelar"
        component={CancelamentoEventos}
        isPrivate
      />
      <PrivateRoute
        path="/detalhe/cancelamento/evento/:event_id"
        component={DetalhesCancelamentoEvento}
        isPrivate
      />
      <PrivateRoute
        path="/financeiro-planos"
        component={PlanosPagamentos}
        isPrivate
      />
      <PrivateRoute path="/auditoria/emails" component={Emails} isPrivate />
      <PrivateRoute
        path="/event-categories"
        component={EventCategorization}
        isPrivate
      />
      <PrivateRoute
        path="/auditoria/SalesTickets"
        component={SalesTickets}
        isPrivate
      />
      <PrivateRoute
        path="/equipments/inventory"
        component={Inventory}
        isPrivate
      />
      <PrivateRoute path="/event/create" component={EventCreate} isPrivate />
      <PrivateRoute
        path="/auditoria/antiFraud"
        component={AntiFraud}
        isPrivate
      />
      <PrivateRoute
        path="/auditoria/sales-details"
        component={AuditDetailedSales}
        isPrivate
      />
      <PrivateRoute
        path="/equipments/my-equipments"
        component={MyEquipments}
        isPrivate
      />
      <PrivateRoute
        path="/ticket-categories"
        component={TicketCategory}
        isPrivate
      />
      <PrivateRoute path="/pdvs-manager" component={PdvsManager} isPrivate />
      <PrivateRoute path="/registerPDV" component={RegisterPDV} isPrivate />
      <PrivateRoute path="/PdvList" component={PdvList} isPrivate />
      <PrivateRoute
        path="/gestao-termo-de-uso"
        component={ListTerms}
        isPrivate
      />
      <PrivateRoute
        path="/TicketEventTransform/:event_id"
        component={TicketEventTransform}
        isPrivate
      />
      <PrivateRoute path="/registerTerms" component={RegisterTerms} isPrivate />

      {/* -------------------------------- USER AREA -------------------------------- */}
      <PrivateRoute path="/users" component={Users} isPrivate />
      <PrivateRoute path="/register-user" component={RegisterUser} isPrivate />
      <PrivateRoute path="/user-data" component={UserData} isPrivate />
      <PrivateRoute path="/user-edit" component={UserEdit} isPrivate />

      {/* --------------------------------- TICKETS AREA ---------------------------- */}
      <PrivateRoute
        path="/tickets/dashboard"
        component={Eventodetalhes}
        isPrivate
      />
      <PrivateRoute
        path="/tickets/seatsmapslist"
        component={EventSeatsMaps}
        isPrivate
      />

      <PrivateRoute
        path="/tickets/integrations"
        component={Integrations}
        isPrivate
      />

      <PrivateRoute
        path="/tickets/ingressos/evento"
        component={Ingressos}
        isPrivate
      />
      <PrivateRoute
        path="/tickets/ingressos/SortingOfSectors/evento"
        component={SortingOfSectors}
        isPrivate
      />
      <PrivateRoute
        path="/tickets/ingressos/grupos/evento"
        component={Passports}
        isPrivate
      />
      <PrivateRoute
        path="/tickets/ingressos/codigos/evento"
        component={PromocionalCode}
        isPrivate
      />
      <PrivateRoute
        path="/tickets/ingressos/comissarios/evento"
        component={Comissarios}
        isPrivate
      />
      <Route
        path="/tickets/ingressos/localizadores/evento"
        component={Localizadores}
        isPrivate
      />
      <PrivateRoute path="/tickets/vendas" component={Vendas} isPrivate />
      <PrivateRoute
        path="/tickets/PDV/vendas"
        component={VendasPDV}
        isPrivate
      />
      <PrivateRoute
        path="/tickets/financeiro-dashboard"
        component={FinanceiroProdutor}
        isPrivate
      />
      <PrivateRoute
        path="/tickets/participantes/evento"
        component={Participantes}
        isPrivate
      />
      <PrivateRoute
        path="/tickets/participantes/checkin"
        component={CameraCheckin}
        isPrivate
      />
      <PrivateRoute
        path="/tickets/nominal-transfers"
        component={NominalTransfers}
        isPrivate
      />
      <PrivateRoute
        path="/tickets/comissarios/vendas"
        component={VendasComissarios}
        isPrivate
      />
      <PrivateRoute
        path="/tickets/event-sales-date/vendas/"
        component={EventSalesDate}
        isPrivate
      />
      <PrivateRoute path="/tickets/checkin" component={Checkin} isPrivate />
      <PrivateRoute
        path="/tickets/new-event-edit"
        component={EventEdit}
        isPrivate
      />
      <PrivateRoute
        path="/tickets/new-event-edit/marketing"
        component={EventEdit}
        isPrivate
      />
      <PrivateRoute
        path="/tickets/new-event-edit/saleConfig"
        component={EventEdit}
        isPrivate
      />
      <PrivateRoute
        path="/tickets/new-event-edit/colaborators"
        component={EventEdit}
        isPrivate
      />
      <PrivateRoute
        path="/tickets/courtesies"
        component={Courtesies}
        isPrivate
      />
      <PrivateRoute
        path="/tickets/marketing/audienceAndVisits"
        component={AudienceAndVisits}
        isPrivate
      />

      <PrivateRoute path="/preferences" component={Preferences} isPrivate />

      {/* --------------------------------- BAR AREA -------------------------------- */}
      <PrivateABRoute
        path="/bar/menu-event-new"
        component={EventMenuNew}
        isPrivate
      />
      <PrivateABRoute
        path="/bar/dashboard"
        component={EventDashboard}
        isPrivate
      />
      <PrivateABRoute
        path="/bar/event-stock"
        component={EventStockNew}
        isPrivate
      />
      <PrivateABRoute
        path="/bar/event-stock-trans"
        component={EventStockTrans}
        isPrivate
      />
      <PrivateABRoute path="/bar/sector" component={Sector} isPrivate />
      <PrivateABRoute path="/bar/register-sector" component={RegisterSector} />
      <PrivateABRoute path="/bar/edit-sector" component={EditSector} />
      <PrivateABRoute
        path="/bar/event-report"
        component={EventReport}
        isPrivate
      />
      <PrivateABRoute
        path="/bar/event-report-category"
        component={ReportCategory}
        isPrivate
      />
      <PrivateABRoute
        path="/bar/establishments-sold"
        component={EstablishmentSold}
        isPrivate
      />
      <PrivateABRoute
        path="/bar/event-categories-report"
        component={CategoriesReport}
        isPrivate
      />
      <PrivateABRoute
        path="/bar/event-commands"
        component={Commands}
        isPrivate
      />
      <PrivateABRoute
        path="/bar/event-commands-daily"
        component={EventComandDaily}
        isPrivate
      />
      <PrivateABRoute path="/bar/config" component={BarConfig} isPrivate />
      <PrivateABRoute
        path="/bar/financeiro-dashboard"
        component={FinanceiroProdutor}
        isPrivate
      />
      <PrivateABRoute
        path="/bar/financeiro-transferencias"
        component={FinanceiroTransferencias}
        isPrivate
      />
      <PrivateABRoute
        path="/bar/filterCaixa"
        component={FilterCaixa}
        isPrivate
      />

      {/* -------------------------------- Page Error ------------------------------- */}
      <Route path="/404" component={PageError} />
      <Route path="*">
        <Redirect to="/eventos" />
      </Route>
    </Switch>
  );
}
