import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { InputGroup } from "react-bootstrap";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { ToastContainer } from "react-toastr";
import styled from "styled-components";
import Swal from "sweetalert2";
import * as yup from "yup";

import Card from "components/card";
import CustomButton from "components/custom-button";
import DataTip from "components/data-tip";
import FormField from "components/FormField";
import UploadImage from "components/uploadImage";
import { cpfMask } from "mask";
import api from "services/api";
import { currencies } from "services/constants";
import { ImageHandlerService } from "services/image-handler";
import { ValidationsHelper } from "services/validations";
import { getAllTimezones } from "utils";

import { ReactComponent as DollarIcon } from "../../../../../assets/icons/dollar-coin-solid.svg";
import { ReactComponent as TimezoneIcon } from "../../../../../assets/icons/timezoneGlobal.svg";

import "./styles.css";

const user = JSON.parse(localStorage.getItem("user"));

General.propTypes = {
  readOnly: PropTypes.bool,
  organization: PropTypes.shape({
    organization: PropTypes.object,
    taxes: PropTypes.object,
    organizationInfo: PropTypes.shape({
      name: PropTypes.string,
      banner: PropTypes.string,
      slug: PropTypes.string,
      document: PropTypes.string,
      owner_document: PropTypes.string,
      social_name: PropTypes.string,
      thumb: PropTypes.string,
      category: PropTypes.string,
      currency: PropTypes.string,
      timezone: PropTypes.string,
    }),
    producer: PropTypes.shape({
      email: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      user_id: PropTypes.number,
    }),
    partnersList: PropTypes.array,
    setOrganization: PropTypes.func,
  }),
  userInformations: PropTypes.object,
  setSteps: PropTypes.func,
  stepId: PropTypes.number,
  nextStepId: PropTypes.number,
  org_id: PropTypes.string,
  callBackEdit: PropTypes.func,
};

const timezoneOptions = getAllTimezones().map(
  ({ formatted, timezone }, index) => ({
    id: index,
    label: formatted,
    value: timezone,
  })
);
const currenciesOptions = currencies.map(({ formatted, value }, index) => ({
  id: index,
  label: formatted,
  value: value,
}));

const createFormSchema = (t) =>
  yup.object({
    name: yup.string().trim().required(t("form.card.validation.required.name")),
    category: yup
      .string()
      .trim()
      .required(t("form.card.validation.required.category")),
    email: yup
      .string()
      .trim()
      .email()
      .required(t("form.card.validation.required.email")),
    first_name: yup
      .string()
      .trim()
      .required(t("form.card.validation.required.name")),
    last_name: yup
      .string()
      .trim()
      .required(t("form.card.validation.required.lastName")),
    social_name: yup
      .string()
      .trim()
      .when("category", {
        is: "Produtor Autônomo",
        then: (schema) => schema.nullable(),
        otherwise: (schema) =>
          schema.required(t("form.card.validation.required.socialName")),
      }),
    document: yup
      .string()
      .trim()
      .when("category", {
        is: "Produtor Autônomo",
        then: (schema) => schema.nullable(),
        otherwise: (schema) =>
          schema
            .required(t("form.card.validation.required.document"))
            .test(
              "documentTest",
              t("form.card.validation.valid.document"),
              (value) =>
                value &&
                (ValidationsHelper.isCpfValid(value) ||
                  ValidationsHelper.isCnpjValid(value))
            ),
      }),
    owner_document: yup
      .string()
      .trim()
      .required(t("form.card.validation.required.ownerDocument"))
      .test(
        "documentTest",
        t("form.card.validation.valid.document"),
        (value) =>
          value &&
          (ValidationsHelper.isCpfValid(value) ||
            ValidationsHelper.isCnpjValid(value))
      ),
    attendant: yup.string().trim().nullable(),
    seller: yup.string().trim().nullable(),
    regional: yup.string().trim().nullable(),
    event_frequency_days: yup
      .number()
      .transform((value) =>
        isNaN(value) || value === null || value === undefined ? null : value
      )
      .nullable()
      .test(
        "frequencyTest",
        t("form.card.validation.frequency"),
        (value) => value == null || (value && value > 0)
      ),
    ...(user.is_admin && {
      trust_level_id: yup
        .string()
        .trim()
        .required(t("form.card.validation.trust-level")),
    }),
    currency: yup.string().trim(),
    timezone: yup.string().trim(),
  });

let container;

export default function General(props) {
  const {
    readOnly,
    setSteps,
    stepId,
    nextStepId,
    org_id,
    organization: {
      organization,
      // The "name" of the organization is being used as a reference
      organizationInfo: { name },
      setOrganization,
      partnersList,
    },
    callBackEdit,
  } = props;
  const [partnersListChild, setPartnerListChild] = useState([]);
  const [oldPartnerList, setOldPartnerList] = useState([]);
  const [trustLevels, setTrustLevels] = useState([]);
  const [disabled, setDisabled] = useState({
    producer: false,
    partner: false,
  });

  const shouldShowCurrencyAndTimeZone = useMemo(() => {
    return (
      props?.organization?.producer?.user_id === props?.userInformations?.id ||
      props?.userInformations?.is_admin === 1 ||
      !org_id
    );
    // eslint-disable-next-line
  }, [props?.organization, props?.user]);

  const { t } = useTranslation(["organizations", "global"], {
    keyPrefix: "configuration.first-step",
  });

  const trustLevelsTranslations = {
    Alto: t("form.card.trust-level-options.high"),
    Médio: t("form.card.trust-level-options.medium"),
    Baixo: t("form.card.trust-level-options.low"),
  };

  const formSchema = createFormSchema(t);

  const categories = [
    {
      id: 1,
      name: "category",
      value: "Produtor Autônomo",
      label: t("form.categories.autonomous-producer"),
    },
    {
      id: 2,
      name: "category",
      value: "Empresa de eventos",
      label: t("form.categories.event-company"),
    },
    {
      id: 3,
      name: "category",
      value: "Estabelecimento",
      label: t("form.categories.establishment"),
    },
  ];

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...organization.organizationInfo,
      category: categories[0].value,
    },
    mode: "all",
    resolver: yupResolver(formSchema),
  });

  const [linkedPartner, setLinkedPartner] = useState({
    id: null,
    picture: "",
    email_partner: "",
    first_name_partner: "",
    last_name_partner: "",
  });

  const switchStyles = {
    control: (control) => ({
      ...control,
      cursor: "pointer",
      boxShadow: "none",
      width: "100%",
      padding: "0.1rem",
      borderColor:
        !categoryChild && errors.category?.message ? "#dc3545" : "#ced4da",
      borderRadius: "0.375rem",
    }),
    placeholder: (placeholder) => ({
      ...placeholder,
      color: "#b1cce6",
    }),
    option: (option, state) => ({
      ...option,
      color: !state.isSelected ? "#6e84a3" : "white",
      cursor: "pointer",
    }),
    singleValue: (singleValue) => ({
      ...singleValue,
      color: "#6e84a3",
    }),
    dropdownIndicator: (dropdownIndicator) => ({
      ...dropdownIndicator,
      color: "#6e84a3",
    }),
  };
  const switchStyles2 = {
    control: (control) => ({
      ...control,
      cursor: "pointer",
      boxShadow: "none",
      width: "100%",
      padding: "0.1rem",
      borderColor:
        !categoryChild && errors.category?.message ? "#dc3545" : "#ced4da",
      borderRadius: "0 0.375rem 0.375rem 0",
    }),
    placeholder: (placeholder) => ({
      ...placeholder,
      color: "#b1cce6",
    }),
    option: (option, state) => ({
      ...option,
      color: !state.isSelected ? "#6e84a3" : "white",
      cursor: "pointer",
    }),
    singleValue: (singleValue) => ({
      ...singleValue,
      color: "#6e84a3",
    }),
    dropdownIndicator: (dropdownIndicator) => ({
      ...dropdownIndicator,
      color: "#6e84a3",
    }),
  };

  const categoryChild = watch("category");
  const trustLevelChild = watch("trust_level_id");
  const nameChild = watch("name");
  const emailChild = watch("email");

  const onSubmit = (form) => {
    const dataValuesFields = {
      ...organization,
      organizationInfo: {
        name: form.name,
        banner: form.banner,
        slug: form.slug,
        document: form.document,
        owner_document: form.owner_document,
        social_name: form.social_name,
        thumb: form.thumb,
        category: form.category,
        seller: form.seller,
        attendant: form.attendant,
        regional: form.regional,
        event_frequency_days: form.event_frequency_days,
        currency: form.currency,
        timezone: form.timezone,
        trust_level_id: form.trust_level_id,
      },
      producer: {
        email: form.email,
        first_name: form.first_name,
        last_name: form.last_name,
        user_id: form.user_id,
      },
      partnersList: form.partnersListChild,
      oldPartnerList: oldPartnerList,
    };
    if (!org_id) {
      setOrganization(dataValuesFields);
      setSteps((prevState) => {
        const newState = prevState.map((obj) => {
          if (stepId === obj.id) {
            return {
              ...obj,
              complete: true,
              currentPosition: nextStepId,
            };
          } else if (nextStepId === obj.id) {
            return {
              ...obj,
              complete: false,
              next: true,
              currentPosition: nextStepId,
            };
          } else {
            return { ...obj, currentPosition: nextStepId };
          }
        });
        return newState;
      });
    } else {
      callBackEdit(dataValuesFields);
    }
  };

  const verifyEmail = (checkEmail, type) => {
    // eslint-disable-next-line
    api
      .get(`/verify/user/${checkEmail}`)
      .then((res) => {
        if (res.data && type === "partner") {
          setLinkedPartner({
            ...linkedPartner,
            id: res.data.id,
            picture: res.data.picture,
            first_name_partner: res.data.first_name,
            last_name_partner: res.data.last_name,
          });
          setDisabled({
            ...disabled,
            partner: true,
          });
        } else if (res.data && type === "producer") {
          if (
            partnersListChild.some(
              (partner) => partner.email_partner === checkEmail
            )
          ) {
            reset({ email: "", first_name: "", last_name: "" });
            return container.error(t("toast.error.userAlreadyLinkedProducer"));
          }

          setValue("first_name", res.data.first_name);
          setValue("last_name", res.data.last_name);
          setValue("user_id", res.data.id);
          setDisabled({
            ...disabled,
            producer: true,
          });
        }
      })
      .catch(() => {
        setLinkedPartner({ ...linkedPartner, id: null });
        setDisabled({
          ...disabled,
          [type]: false,
        });
      });
  };

  const linkingProducers = () => {
    let arrayPartner = [];
    if (
      !linkedPartner.email_partner ||
      !linkedPartner.first_name_partner ||
      !linkedPartner.last_name_partner
    ) {
      return container.error(t("toast.error.fillAllFields"));
    }
    if (partnersListChild && partnersListChild.length > 0) {
      arrayPartner = [...partnersListChild];
    }

    if (
      arrayPartner.some(
        (dataInfo) => dataInfo.email_partner === linkedPartner.email_partner
      )
    ) {
      return container.error(t("toast.error.userAlreadyLinked"));
    } else if (emailChild === linkedPartner.email_partner) {
      return container.error(t("toast.error.userAlreadyOwn"));
    }
    arrayPartner.push(linkedPartner);
    setPartnerListChild(arrayPartner);
    setValue("partnersListChild", partnersListChild);
    setLinkedPartner({
      id: null,
      email_partner: "",
      picture: "",
      first_name_partner: "",
      last_name_partner: "",
    });
    setDisabled({
      ...disabled,
      partner: false,
    });
    setOrganization({
      ...organization,
      partnersList: arrayPartner,
    });
  };

  const removeProducers = (key) => {
    const arrayPartner = [...partnersListChild];
    arrayPartner.splice(key, 1);
    setPartnerListChild(arrayPartner);
    setOrganization({ ...organization, partnersList: arrayPartner });
  };

  const selectedCategory = ({ value }) => {
    setValue("category", value);
    setValue("document", "");
    setValue("social_name", "");
  };

  const generatedUrl = (url, uploadType) => {
    uploadType === "banner" ? setValue("banner", url) : setValue("thumb", url);
  };

  const uploadError = () => {
    Swal.fire({
      title: t("global:ops"),
      icon: "error",
      text: t("global:errors.uploadError"),
      confirmButtonText: "Ok",
    });
  };

  const handleTimezoneAndCurrency = (name, { value }) => {
    setOrganization((prevState) => ({
      ...prevState,
      organizationInfo: {
        ...prevState.organizationInfo,
        [name]: value,
      },
    }));
  };

  useEffect(() => {
    setValue("partnersListChild", partnersListChild);
    // eslint-disable-next-line
  }, [partnersListChild]);

  useEffect(() => {
    if (nameChild) {
      const a = "àáäâãèéëêìíïîòóöôùúüûñçßÿỳýœæŕśńṕẃǵǹḿǘẍźḧ";
      const b = "aaaaaeeeeiiiioooouuuuncsyyyoarsnpwgnmuxzh";
      const p = new RegExp(a.split("").join("|"), "g");

      // name = Organization's name.
      const text = nameChild
        .toString()
        .toLowerCase()
        .replace(/[\s_]+/g, "-")
        .replace(p, (c) => b.charAt(a.indexOf(c)))
        .replace(/[^\w-]+/g, "")
        .replace(/--+/g, "-")
        .replace(/^-+|-+$/g, "");

      setValue("slug", text);
    } else {
      setValue("slug", "");
    }
    // eslint-disable-next-line
  }, [nameChild]);

  useEffect(() => {
    if (name) {
      for (const key in organization.organizationInfo) {
        if (Object.hasOwnProperty.call(organization.organizationInfo, key)) {
          const taxContent = organization.organizationInfo[key];
          if (!["partnerList"].includes(key)) {
            setValue(`${key}`, taxContent);
          }
        }
      }
      setPartnerListChild(partnersList || []);
      setOldPartnerList(partnersList || []);
      setValue("email", organization.producer.email);
      setValue("first_name", organization.producer.first_name);
      setValue("last_name", organization.producer.last_name);
      setValue("user_id", organization.producer.user_id);
    }
    // eslint-disable-next-line
  }, [name]);

  const handleTrustLevels = (e) => {
    setValue("trust_level_id", e.value);
  };

  useEffect(() => {
    setValue("currency", organization.organizationInfo.currency);
    setValue("timezone", organization.organizationInfo.timezone);
    // eslint-disable-next-line
  }, [
    organization.organizationInfo.currency,
    organization.organizationInfo.timezone,
  ]);

  useEffect(() => {
    if (user.is_admin) {
      api
        .get("/organization/trustLevels")
        .then(({ data }) => {
          const levels = data.map(({ id, name }) => ({
            id,
            value: id,
            label: name,
          }));
          if (!org_id) {
            handleTrustLevels(levels[0]);
          }
          setTrustLevels(levels);
        })
        .catch((err) => console.error(err));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Form>
      <ToastContainer
        ref={(ref) => (container = ref)}
        className="toast-top-right"
      />
      <Row>
        <Col className="header-container">
          <Col>
            <h1 className="header-title">{t("form.header.title")}</h1>
            <p className="header-postitle">{t("form.header.text")}</p>
          </Col>
        </Col>
      </Row>
      <Card header={t("form.card.header.info")}>
        <Row>
          <Col md="6">
            <FormField
              label={t("form.card.field-label.name")}
              errorMessage={errors.name?.message}
              controlId="validationName"
            >
              <Form.Control
                {...register("name")}
                readOnly={readOnly}
                name="name"
                autoComplete="nope"
                type="text"
                placeholder={t("form.card.placeholder.name")}
                isInvalid={errors.name?.message}
              />
            </FormField>
          </Col>
          <Col md="6">
            <FormField
              label={t("form.card.field-label.slug")}
              errorMessage={errors.slug?.message}
              controlId="validationSlug"
            >
              <Form.Control
                {...register("slug")}
                name="slug"
                autoComplete="nope"
                type="text"
                disabled={true}
                placeholder={t("form.card.field-label.slug")}
                isInvalid={errors.slug?.message}
              />
            </FormField>
          </Col>
        </Row>
        <Row>
          <Col md={user.is_admin ? "8" : "12"}>
            <Row>
              <Col md="6">
                <FormField
                  label={t("form.card.field-label.category")}
                  controlId="validationCategory"
                >
                  <Select
                    styles={switchStyles}
                    className="col-12 p-0"
                    isDisabled={readOnly}
                    value={
                      categoryChild
                        ? categories.filter(
                            (option) => option.value === categoryChild
                          )
                        : categories[0]
                    }
                    onChange={selectedCategory}
                    options={categories}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    placeholder={t("form.card.placeholder.category")}
                  />
                  {!categoryChild && errors.category?.message && (
                    <TextH6 className="text-danger ml-2 mt-1">
                      <TextSmall className="text-danger">
                        {t("form.card.validation.required.category")}
                      </TextSmall>
                    </TextH6>
                  )}
                </FormField>
              </Col>
              <Col md="6">
                <FormField
                  label={t("form.card.field-label.owner-document")}
                  errorMessage={errors.owner_document?.message}
                  controlId="validationOwnerDocument"
                >
                  <Form.Control
                    {...register("owner_document")}
                    type="text"
                    autoComplete="nope"
                    readOnly={readOnly}
                    onChange={(e) =>
                      setValue("owner_document", cpfMask(e.target.value))
                    }
                    placeholder={t("form.card.placeholder.owner-document")}
                    isInvalid={errors.owner_document?.message}
                  />
                </FormField>
              </Col>
            </Row>
          </Col>
          {!!user.is_admin && (
            <Col md="4">
              <FormField
                label={
                  <Fragment>
                    <Form.Row className="row ml-1">
                      {t("form.card.field-label.trust-level")}
                      <DataTip
                        tipContentClass="tip-trust-level"
                        tipContent={t("form.card.tip.trust-level")}
                      />
                    </Form.Row>
                  </Fragment>
                }
                errorMessage={errors.trust_level_id?.message}
                controlId="validationTrustLevel"
              >
                <Select
                  id="select-trust-level"
                  isSearchable={false}
                  styles={switchStyles}
                  style={{ marginRight: "500px !important" }}
                  className="col-12 p-0"
                  isDisabled={readOnly}
                  value={
                    trustLevelChild
                      ? trustLevels.filter(
                          (option) => option.value === trustLevelChild
                        )
                      : trustLevels[0]
                  }
                  onChange={handleTrustLevels}
                  options={trustLevels.map((trustLevel) => ({
                    ...trustLevel,
                    label: trustLevelsTranslations[trustLevel.label],
                  }))}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  placeholder={t("form.card.placeholder.category")}
                />
                {!trustLevelChild && errors.trust_level_id?.message && (
                  <TextH6 className="text-danger ml-2 mt-1">
                    <TextSmall className="text-danger">
                      {t("form.card.validation.required.trust-level")}
                    </TextSmall>
                  </TextH6>
                )}
              </FormField>
            </Col>
          )}
        </Row>
        {categoryChild && categoryChild !== "Produtor Autônomo" && (
          <Row>
            <Col md="6">
              <FormField
                label={t("form.card.field-label.social-name")}
                errorMessage={errors.social_name?.message}
                controlId="validationSocialName"
              >
                <Form.Control
                  {...register("social_name")}
                  readOnly={readOnly}
                  autoComplete="nope"
                  type="text"
                  placeholder={t("form.card.placeholder.social-name")}
                  isInvalid={
                    categoryChild !== "Produtor Autônomo" &&
                    errors.social_name?.message
                  }
                />
              </FormField>
            </Col>
            <Col md="6">
              <FormField
                label={t("form.card.field-label.document")}
                errorMessage={errors.document?.message}
                controlId="validationDocument"
              >
                <Form.Control
                  {...register("document")}
                  readOnly={readOnly}
                  autoComplete="nope"
                  type="text"
                  onChange={(e) =>
                    setValue("document", cpfMask(e.target.value))
                  }
                  placeholder={t("form.card.field-label.document")}
                  isInvalid={
                    categoryChild !== "Produtor Autônomo" &&
                    errors.document?.message
                  }
                />
              </FormField>
            </Col>
          </Row>
        )}
        {shouldShowCurrencyAndTimeZone && (
          <Row>
            <Col md="6 mt-4">
              <FormField
                label={t("form.card.field-label.currency")}
                controlId="validationCurrency"
              >
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <DollarIcon />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Select
                    styles={switchStyles2}
                    isSearchable={false}
                    className="col p-0"
                    isDisabled={readOnly}
                    value={
                      organization.organizationInfo.currency
                        ? currenciesOptions.filter(
                            (option) =>
                              option.value ===
                              organization.organizationInfo.currency
                          )
                        : ""
                    }
                    onChange={(value) =>
                      handleTimezoneAndCurrency("currency", value)
                    }
                    options={currenciesOptions}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                </InputGroup>
              </FormField>
            </Col>
            <Col md="6 mt-4">
              <FormField
                label={t("form.card.field-label.timezone")}
                controlId="validationTimezone"
              >
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <TimezoneIcon />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Select
                    styles={switchStyles2}
                    className="col p-0"
                    isDisabled={readOnly}
                    value={
                      organization.organizationInfo.timezone
                        ? timezoneOptions.filter(
                            (option) =>
                              option.value ===
                              organization.organizationInfo.timezone
                          )
                        : ""
                    }
                    onChange={(value) =>
                      handleTimezoneAndCurrency("timezone", value)
                    }
                    options={timezoneOptions}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                </InputGroup>
              </FormField>
            </Col>
          </Row>
        )}
        <Row>
          <Col md="6 mt-4">
            <UploadImage
              size="1800 x 350"
              defaultValue={organization.organizationInfo.banner}
              disabled={readOnly}
              uploadType={t("form.upload-type.banner")}
              uploadError={uploadError}
              generatedUrl={generatedUrl}
            />
          </Col>
          <Col md="6 mt-4">
            <UploadImage
              size="300 x 300"
              defaultValue={organization.organizationInfo.thumb}
              disabled={readOnly}
              uploadType={t("form.upload-type.thumb")}
              uploadError={uploadError}
              generatedUrl={generatedUrl}
            />
          </Col>
        </Row>
        <hr />
        <Form.Text
          style={{
            fontSize: "0.975rem",
            color: "#4f6c7c",
            fontWeight: "500",
          }}
        >
          {t("form.card.section.title.owner-producer")}
        </Form.Text>
        <Form.Text
          style={{
            fontStyle: "italic",
            color: "#8fa5b2",
          }}
        >
          {t("form.card.section.text.owner-producer")}
        </Form.Text>
        <Row>
          <Col md="4">
            <FormField
              label={t("form.card.field-label.email")}
              errorMessage={errors.email?.message}
              controlId="validationEmail"
            >
              <Form.Control
                {...register("email")}
                readOnly={readOnly}
                autoComplete="nope"
                onBlur={(e) => verifyEmail(e.target.value, "producer")}
                type="text"
                placeholder={t("form.card.placeholder.email")}
                isInvalid={errors.email?.message}
              />
            </FormField>
          </Col>
          <Col md="4">
            <FormField
              label={t("form.card.field-label.name")}
              errorMessage={errors.first_name?.message}
              controlId="validationFirstName"
            >
              <Form.Control
                {...register("first_name")}
                readOnly={readOnly}
                type="text"
                autoComplete="nope"
                placeholder={t("form.card.field-label.name")}
                disabled={disabled.producer}
                isInvalid={errors.first_name?.message}
              />
            </FormField>
          </Col>
          <Col md="4">
            <FormField
              label={t("form.card.field-label.last-name")}
              errorMessage={errors.last_name?.message}
              controlId="validationLastName"
            >
              <Form.Control
                {...register("last_name")}
                readOnly={readOnly}
                type="text"
                autoComplete="nope"
                placeholder={t("form.card.field-label.last-name")}
                disabled={disabled.producer}
                isInvalid={errors.last_name?.message}
              />
            </FormField>
          </Col>
        </Row>
        <hr />
        <Form.Text
          style={{
            fontSize: "0.975rem",
            color: "#4f6c7c",
            fontWeight: "500",
          }}
        >
          {t("form.card.section.title.linked-producer")}
        </Form.Text>
        <Form.Text
          style={{
            fontStyle: "italic",
            color: "#8fa5b2",
          }}
        >
          {t("form.card.section.text.linked-producer")}
        </Form.Text>
        {!readOnly && (
          <Row className="mb-4">
            <Col md="3">
              <FormField
                label={t("form.card.field-label.email")}
                controlId="validationEmailPartner"
              >
                <Form.Control
                  value={linkedPartner.email_partner}
                  name="email_partner"
                  autoComplete="nope"
                  readOnly={readOnly}
                  onBlur={(e) => verifyEmail(e.target.value, "partner")}
                  onChange={(e) =>
                    setLinkedPartner({
                      ...linkedPartner,
                      [e.target.name]: e.target.value,
                    })
                  }
                  type="text"
                  placeholder={t("form.card.placeholder.email")}
                />
              </FormField>
            </Col>
            <Col md="3">
              <FormField
                label={t("form.card.field-label.name")}
                controlId="validationFirstNamePartner"
              >
                <Form.Control
                  value={linkedPartner.first_name_partner}
                  disabled={disabled.partner}
                  readOnly={readOnly}
                  autoComplete="nope"
                  name="first_name_partner"
                  onChange={(e) =>
                    setLinkedPartner({
                      ...linkedPartner,
                      [e.target.name]: e.target.value,
                    })
                  }
                  type="text"
                  placeholder={t("form.card.field-label.name")}
                />
              </FormField>
            </Col>
            <Col md="3">
              <FormField
                label={t("form.card.field-label.last-name")}
                controlId="validationLastNamePartner"
              >
                <Form.Control
                  value={linkedPartner.last_name_partner}
                  name="last_name_partner"
                  readOnly={readOnly}
                  autoComplete="nope"
                  disabled={disabled.partner}
                  onChange={(e) =>
                    setLinkedPartner({
                      ...linkedPartner,
                      [e.target.name]: e.target.value,
                    })
                  }
                  type="text"
                  placeholder={t("form.card.field-label.last-name")}
                />
              </FormField>
            </Col>
            <Col md="3">
              <CustomButton
                disabled={readOnly}
                className="btn btn-outline-secondary btn-height mt-4"
                onClick={linkingProducers}
                text={t("form.card.button.add-producer")}
              />
            </Col>
          </Row>
        )}
        {partnersListChild &&
          partnersListChild.length > 0 &&
          partnersListChild.map((item, i) => (
            <Row className="mb-3 pl-2 pt-2" key={i}>
              <Col
                className="user-img-organization"
                style={{
                  backgroundImage: `url${
                    item.picture
                      ? `(${ImageHandlerService.getImageFromUrl(item.picture, {
                          format: "webp",
                          quality: 50,
                          resize: { width: 100, fit: "contain" },
                        })})`
                      : `(${"../../avatar.jpg"})`
                  }`,
                }}
              />
              <Col
                className="d-flex align-items-start justify-content-center"
                style={{ flexDirection: "column" }}
              >
                <TextH6>
                  {item.first_name_partner + " " + item.last_name_partner}
                </TextH6>
                <TextSmall>{item.email_partner}</TextSmall>
              </Col>
              {!readOnly && (
                <Col className="d-flex align-items-center justify-content-end">
                  <CustomButton
                    onClick={() => removeProducers(i)}
                    className="btn-table"
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </CustomButton>
                </Col>
              )}
            </Row>
          ))}
      </Card>

      {!!user.is_admin && (
        <Card header={t("form.card.header.comercial-and-atendant")}>
          <Row>
            <Col md="4">
              <FormField
                label={t("form.card.field-label.seller-name")}
                errorMessage={errors.seller?.message}
                controlId="validationSeller"
              >
                <Form.Control
                  {...register("seller")}
                  readOnly={readOnly}
                  name="seller"
                  autoComplete="nope"
                  type="text"
                  placeholder={t("form.card.placeholder.seller-name")}
                  isInvalid={errors.seller?.message}
                />
              </FormField>
            </Col>
            <Col md="4">
              <FormField
                label={t("form.card.field-label.attendant-name")}
                errorMessage={errors.attendant?.message}
                controlId="validationAttendant"
              >
                <Form.Control
                  {...register("attendant")}
                  readOnly={readOnly}
                  name="attendant"
                  autoComplete="nope"
                  type="text"
                  placeholder={t("form.card.placeholder.attendant-name")}
                  isInvalid={errors.attendant?.message}
                />
              </FormField>
            </Col>
            <Col md="4">
              <FormField
                label={t("form.card.field-label.regional-name")}
                errorMessage={errors.regional?.message}
                controlId="validationRegional"
              >
                <Form.Control
                  {...register("regional")}
                  readOnly={readOnly}
                  name="regional"
                  autoComplete="nope"
                  type="text"
                  placeholder={t("form.card.placeholder.regional-name")}
                  isInvalid={errors.regional?.message}
                />
              </FormField>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormField
                label={t("form.card.field-label.event-frequency")}
                errorMessage={errors.event_frequency_days?.message}
                controlId="validationDays"
              >
                <Form.Control
                  {...register("event_frequency_days")}
                  readOnly={readOnly}
                  name="event_frequency_days"
                  autoComplete="nope"
                  type="number"
                  placeholder={t("form.card.placeholder.event-frequency")}
                  isInvalid={errors.event_frequency_days?.message}
                />
              </FormField>
            </Col>
          </Row>
        </Card>
      )}

      {!readOnly && (
        <Row className="justify-content-end">
          <Col md="3" sm="12" className="mb-2">
            <CustomButton
              onClick={handleSubmit(onSubmit)}
              className="btn btn-primary col-12"
              text={t(
                org_id ? "form.card.button.save" : "form.card.button.next"
              )}
            />
          </Col>
        </Row>
      )}
    </Form>
  );
}

//////////////////// COMPONENTs ////////////////////

const TextH6 = styled.h6``;

const TextSmall = styled.small``;
